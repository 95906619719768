import { CabButton, CabIcon, CabModal, CabSwitch } from "@CabComponents";
import { Box, styled, Typography } from "@mui/material";
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { ReactElement, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import colors from "../../../colors";
import { EVENT_TYPE, NEW_TIER, TIER } from "../../../constants";
import StepHeader from "../StepHeader";
import { trackEventWithExtra } from "../../../utils/appAnalyticsUtils";


export interface FormInput {
  billedAnnually: boolean;
  planTier: TIER;
}

export interface PlanSelectionProps {
  defaultSelection?: {
    billedAnnually?: boolean;
    planTier?: TIER;
  };
  planOptions: {
    id: TIER;
    name: string;
    costMonthly: number;
    costAnnually: number;
    caption: string;
    details: string[];
    buttonText: string;
    planDescription: string;
  }[];
  dialogError?: string;
  onDismissError: () => void;
  onFinish: (values: FormInput) => void;
  onLogout: () => void;
}

const PlanItem = ({ title, cost, selected, onSelect, caption, details, buttonText, planDescription }: {
  title: string, cost: number, caption: string, details: string[], buttonText: string, planDescription: string,
  selected: boolean, onSelect: () => void,
}) => (
  <Box display="flex" flexDirection="column" width="100%" padding={1.5} height={370}
    flex={1} borderColor={selected ? colors.black900 : colors.black300} maxWidth={250} justifyContent="space-between"
    sx={{ borderWidth: 1, borderStyle: 'solid', borderRadius: 1, backgroundColor: colors.white900 }}
  >
    <Box display="flex" flexDirection="row" gap={2} width="100%" alignItems="center" overflow={'hidden'}>
      <Box display="flex" flexDirection="column" gap={1} width="100%">
        <Typography variant="h2" fontSize={22} color={colors.black600}>{title}</Typography>
        <Box display="flex" flexDirection="column" gap={0.5} alignItems="baseline">
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <Typography variant="h3" whiteSpace="nowrap" fontSize={24} fontWeight={700}>
              {cost ? `$${cost}` : 'Free'}
            </Typography>
            {!!cost && (
              <Typography variant="body1" whiteSpace="nowrap">
                / month
              </Typography>
            )}
          </Box>
          <Typography variant="caption" fontSize={12} whiteSpace="nowrap">
            {caption}
          </Typography>

          <Typography fontSize={15}>
            {planDescription}
          </Typography>

          <Box component={'ul'} paddingLeft={2} marginTop={0}>
            {details.map(detail => (
              <Typography key={detail} fontSize={15} component={'li'}>
                {detail}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>

    {selected ? (
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" gap={1}>
        <CabIcon Icon={IoCheckmarkCircleOutline} />
        <Typography variant="body2" fontWeight={500}>
          Selected
        </Typography>
      </Box>
    ) : (
      <CabButton
        onClick={onSelect}
        buttonType="secondary"
        sx={{ 
          color: colors.black900, backgroundColor: colors.black200, width: '100%', paddingTop: 1, paddingBottom: 1 
        }}
      >
        {buttonText}
      </CabButton>
    )}
  </Box>
);

const SwitchStyled = styled(CabSwitch, {label: "CabSwitchDark"})(({ theme }) => ({
  '& .MuiSwitch-track': {
    backgroundColor: colors.black900,
    opacity: 1,
  },
  '&:hover': {
    '& .MuiSwitch-track': {
      backgroundColor: colors.black800,
      opacity: 1,
      '& .MuiSwitch-checked': {
        backgroundColor: colors.black800,
        opacity: 1,
      },
    },
  },
}));

const PlanSelection = ({
  defaultSelection, planOptions, dialogError, onDismissError, onFinish, onLogout
}: PlanSelectionProps): ReactElement => {
  const { control, formState: { isSubmitting }, handleSubmit: handleFormSubmit, watch, setValue } = useForm<FormInput>({
    defaultValues: { billedAnnually: false, planTier: NEW_TIER.STARTER},
  });

  useEffect(() => {
    if (defaultSelection?.planTier) {
      setValue('planTier', defaultSelection.planTier);
    }
    if (defaultSelection?.billedAnnually) {
      setValue('billedAnnually', defaultSelection.billedAnnually);
    }
  }, [defaultSelection?.billedAnnually, defaultSelection?.planTier, setValue]);

  const billedAnnually = watch('billedAnnually');
  const selectedPlan = watch('planTier');

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      <StepHeader
        title="Confirm your plan"
        text=""
      />

      <Box display="flex" flexDirection="column" width="100%" flex={1} alignItems="center">
        <Box display="flex" flexDirection="row" gap={2} marginTop={5} marginBottom={2} flex={1} alignItems="center">
          <Controller name="billedAnnually" control={control}
            render={({ field: { ref, ...field } }) => (
              <>
                <Typography fontWeight={700} color={!field.value ? colors.black900 : colors.black400}>
                  Billed monthly
                </Typography>
                <SwitchStyled {...field} />
                <Typography fontWeight={700} color={field.value ? colors.black900 : colors.black400}>
                  Billed annually
                </Typography>
              </>
            )}
          />
        </Box>
        <Box display="flex" flexDirection="row" gap={3} marginTop={4} width="100%" flex={1} justifyContent="center">
          <Controller name="planTier" control={control}
            render={({ field: { ref, value, onChange, ...field } }) => (
              <>
                {planOptions.map(plan => (
                  <PlanItem
                    key={plan.id}
                    title={plan.name}
                    cost={billedAnnually ? plan.costAnnually : plan.costMonthly}
                    caption={plan.caption}
                    details={plan.details}
                    selected={value === plan.id}
                    onSelect={() => {
                      onChange(plan.id);
                      trackEventWithExtra({ 
                        eventName: EVENT_TYPE.ONBOARD_TIER_SELECTION, 
                        extra: {tier: plan.id} 
                      });
                    }}
                    buttonText={plan.buttonText}
                    planDescription={plan.planDescription}
                  />
                ))}
              </>
            )}
          />
        </Box>
      </Box>

      <Box display="flex" width="100%" justifyContent="center" marginTop={6}>
        <Box display="flex" flexDirection="column" gap={4}>
          <CabButton
            size="large"
            onClick={() => handleFormSubmit(onFinish)()}
            disabled={isSubmitting}
            sx={{ width: 190 }}
          >
            {selectedPlan === NEW_TIER.BASIC ? "Continue" :
              [NEW_TIER.INDIVIDUAL, NEW_TIER.STARTER].includes(selectedPlan) ?
                "Start Free Trial" : "Continue"}
          </CabButton>
          <CabButton
            size="large"
            buttonType="text"
            onClick={onLogout}
            disabled={isSubmitting}
            sx={{ width: 190, textDecoration: 'underline' }}
          >
            Logout
          </CabButton>
        </Box>
      </Box>

      <CabModal
        isAlert
        open={!!dialogError}
        text={dialogError}
        aria-labelledby='Payment start failed'
        aria-describedby='Payment start failed'
        actionButtons={
          <CabButton onClick={onDismissError}>Close</CabButton>
        }
      />
    </Box>
  );
};

export default PlanSelection;
